import React from "react";
import "./naljepniceHR.scss";
import { useParams } from "react-router-dom";
import { setAcceptIncomming } from "../../../components/SIPJS";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import * as urlModule from "../../../modules/urlModule";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Spinner from "../../../components/customComponents/Spinner";
import Articles from "../../AdminPanel/Articles";

const GenerateLabelsPosteHR = () => {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState();
    const idsRef = React.useRef();
    const [searchParams] = useSearchParams();
    const ids = searchParams.get('IDs');
    const targetRef = React.useRef();


console.log("jel usaoooooo", ids)


    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getDataForShipmentLabels`,
            data: {
                deals: ids.split(","),
            },
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === "ok") {
                let tmp = res.data.data;
                tmp.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
                setData({ status: "ok", data: tmp });
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            }
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        console.log(ids)
        if (!ids) return;
        getData();
    }, [ids]);

    React.useEffect(() => {
        const rootImg = document.querySelector("#rootImg");
        if (rootImg) rootImg.remove();
        setAcceptIncomming(false);
    }, []);

    return (
        <div className="route__kreirajNaljepniceHR" ref={targetRef}>
            {spinner && <Spinner />}
            {(() => {
                let final = [];
                if (!data) return null;
                for (let i = 0; i <= data.data.length - 1; i += 4) {
                    final.push(
                        <div className="route__kreirajNaljepniceHR__wrap" key={i}>
                            {data.data.slice(i, i + 4).map((item, index) => (
                                <Naljepnica key={index} data={item} />
                            ))}
                        </div>
                    );
                }
                return final;
            })()}
        </div>
    );
};

const Naljepnica = ({ data }) => {
    const barcodeRef = React.useRef();
    const generatedRef = React.useRef(false);
    const [finalCode, setFinalCode] = React.useState();

    const fillData = (text) => {
        text = String(text);
        while (text.length < 10) text = "0" + text;
        return text;
    };

    React.useEffect(() => {
        if (!data || !barcodeRef.current || generatedRef.current) return;
        let finalCodeTmp = String(data.DealID).padStart(7, '0');
        finalCodeTmp = `006${finalCodeTmp}`;
        setFinalCode(finalCodeTmp);

        let code = new window.Code128(data.ShippmentTrackingCode);
        code.insert(barcodeRef.current);
        generatedRef.current = true;
    }, [data]);

    return (
        <div className="route__kreirajNaljepniceHR__naljepnica" style={{ border: '1px solid black', padding: '10px', fontFamily: 'Arial, sans-serif' }}>
            <div className="route__kreirajNaljepniceHR__naljepnica__left" >
                <div className="route__kreirajNaljepniceHR__naljepnica__left__dexpress">
                    <p style={{ fontWeight: 'bold', textAlign: 'center' }}>HRVATSKA POŠTA D.D.</p>
                    <p style={{ textAlign: 'center' }}>0800 202 202</p>
                </div>
                <div className="route__kreirajNaljepniceHR__naljepnica__right__sender" style={{ marginBottom: '20px' }}>
                    <h3>Pošiljatelj:</h3>
                    <p>Natura Lifted d.o.o</p>
                    <p>Trg profesora Martina Robotića 2</p>
                    <p>32270 Županja</p>
                    <p style={{ marginTop: "20px" }}>Natura Lifted d.o.o 38532373309
                    </p>
                </div>
                    <div ref={barcodeRef} className="route__kreirajNaljepniceHR__naljepnica__left__barcode"></div>
                    <div className="route__kreirajNaljepniceHR__naljepnica__left__barcodeBottom" style={{ textAlign: 'left', marginTop: '10px' }}>{data.ShippmentTrackingCode}</div>
                    <div className="route__kreirajNaljepniceHR__naljepnica__right__recipient">
                    <h3>Primatelj:</h3>
                    <p>{data.BuyerName}</p>
                    <p>{data.BuyerAddress}</p>
                    <p>{data?.BuyerTown?.toUpperCase()} {data.BuyerZIP}</p>
                    <p>Telefon: {data.BuyerPhone}</p>
                    {data.Note && <p>INFO: <b>{data.Note}</b></p>}
                </div>
                <div className="ostalipodaci" style={{display:"flex", alignItems:"center"}}>
                    <div>
                        <p>D+1</p>
                        <p>Otkupna pošiljka (Bez naloga)</p>
                        <p>Pošiljatelj</p>
                        <p>Po ugovoru</p>
                    </div>
                    <div>
                        <p>D1</p>
                        <p>OTK: {data.ProductPayout} EUR</p>
                        <p>Pos.ref: {data.ID} {data.ProductName}</p>
                        <p>Vrijednost: {data.ProductPayout} EUR</p>
                    </div>
                </div>
                
            </div>
            <div className="route__kreirajNaljepniceHR__naljepnica__right" style={{ float: 'right', width: '50%' }}>

               
            </div>
            <div style={{ clear: 'both' }}></div>
        </div>
    );
};

export default GenerateLabelsPosteHR;
