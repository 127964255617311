import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import SingleLead from "../../../components/SingleLead";
import { animateBox } from "../../../modules/componentAnimation";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../components/customComponents/Dropdown";
import { useSearchParams } from "react-router-dom";




const AgentsDashboard = props => {
    const [timerUpdate, setTimerUpdate] = React.useState();
    const [searchParams] = useSearchParams();
    const [data, setData] = React.useState();
    const [totalData, setTotalData] = React.useState();
    const [best, setBest] = React.useState();
    const [visibleData, setVisibleData] = React.useState([]);
    const [startIndex, setStartIndex] = React.useState(0);
    const itemsPerPage = 15;
    const intervalTime = 30000;

    const [updatedAt, setUpdatedAt] = React.useState();

    const cc = searchParams.get('cc');
    const theme = searchParams.get('theme');

    React.useEffect(() => {

        const interval = setInterval(() => {
            setTimerUpdate(Math.random());
        }, 300000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        if (theme === "dark") {
            document.documentElement.style.setProperty('--primary-color', '#3d3d55')
            document.documentElement.style.setProperty('--text-main', 'white')
            document.documentElement.style.setProperty('--text-gray', 'white');
            document.documentElement.style.setProperty('--text-blue', 'gray');
            document.documentElement.style.setProperty('--table-second', '#5e6584');
        } else {
            document.documentElement.style.setProperty('--primary-color', 'white');
            document.documentElement.style.setProperty('--text-main', '#202830;');
            document.documentElement.style.setProperty('--text-gray', '#6f767d');
            document.documentElement.style.setProperty('--text-blue', '#1b1c79');
            document.documentElement.style.setProperty('--table-second', '#e2e6f8');
        }
    }, [theme]);



    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/getAgentsDashboard`,
            data: {
                cc: cc
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data.data.users.sort((a, b) => b.today.revenue - a.today.revenue));
                setTotalData(res.data.data.total)
                setUpdatedAt(moment().format("HH:mm"));
                let tmpBest = {
                    bestRevT: res.data.data.users.sort((a, b) => b.today.revenue - a.today.revenue)[0],
                    bestBonusT: res.data.data.users.sort((a, b) => b.today.bonus - a.today.bonus)[0],
                    bestRevY: res.data.data.users.sort((a, b) => b.yesterday.revenue - a.yesterday.revenue)[0],
                    bestBonusY: res.data.data.users.sort((a, b) => b.yesterday.bonus - a.yesterday.bonus)[0],
                    bestRevW: res.data.data.users.sort((a, b) => b.week.revenue - a.week.revenue)[0],
                    bestBonusW: res.data.data.users.sort((a, b) => b.week.bonus - a.week.bonus)[0],
                    bestRevM: res.data.data.users.sort((a, b) => b.month.revenue - a.month.revenue)[0],
                    bestBonusM: res.data.data.users.sort((a, b) => b.month.bonus - a.month.bonus)[0],
                    // bestADPT: res.data.data.users.sort((a, b) => b.today.revenue / b.today.deals.length - a.today.revenue / a.today.deals.length)[0],
                    // bestART: res.data.data.users.sort((a, b) => b.today.deals.length / b.today.leads.length - a.today.deals.length / a.today.leads.length)[0],
                    // bestDR: res.data.data.users
                    //     .filter(user => user.month.deals.length > 0 && user.month.dealsDelivered.length > 0)
                    //     .sort((a, b) => b.month.dealsDelivered.length / b.month.deals.length - a.month.dealsDelivered.length / a.month.deals.length)[0] || null

                }
                setBest(tmpBest);
            }

        }).catch(e => console.log(e))
    }

    React.useEffect(() => {
        getData()
    }, [cc, timerUpdate])

    React.useEffect(() => {
        if (!data || data.length === 0) return;

        const sortedData = [...data].sort((a, b) => b.today.revenue - a.today.revenue);

        setVisibleData(sortedData.slice(startIndex, startIndex + itemsPerPage));

        const interval = setInterval(() => {
            setStartIndex(prevIndex => {
                const nextIndex = prevIndex + itemsPerPage;

                if (nextIndex >= sortedData.length) {
                    return 0;
                }
                return nextIndex;
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, [data, startIndex]);

    const safeNumber = (value, dec) => {
        if (!dec) dec = 0;
        const num = Number(value);
        return isNaN(num) ? 0 : num.toFixed(dec).toLocaleString();
    };

    return <div className="component__agentsDashboard" >
        <div className="component__agentsDashboard__header">
            <img src="images/landing-page/footer-logo.svg" />
            <p>Call centar: <bold style={{ textTransform: "capitalize" }}>{cc}</bold></p>
            {data && <p>Stranica: {startIndex / itemsPerPage + 1}/{Math.ceil(data.length / itemsPerPage)}</p>}
            <p>Posljednji put ažurirano u {updatedAt}</p>
        </div>
        <div className="component__agentsDashboard__content">
            <div className="component__agentsDashboard__content__kpisLeft">
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći promet danas
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" /> <span> €{best?.bestRevT ? safeNumber(best.bestRevT.today.revenue, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestRevT?.img ? best?.bestRevT?.img : "/images/undefined.png"} />
                        <span>{best?.bestRevT ? best.bestRevT.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">
                        {/* <div>
                            <p>Broj dealova</p>
                            <p>{best?.bestRevT ? Number(best.bestRevT.today.deals.length) : "?"}</p>
                        </div> */}
                        <div style={{ background: "#29653a1f", width: "100%" }}>
                            <p>ADP</p>
                            <p>€{best?.bestRevT ? safeNumber(best.bestRevT.today.revenue / best.bestRevT.today.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći promet Jučer
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" /> <span> €{best?.bestRevY ? safeNumber(best.bestRevY.yesterday.revenue, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestRevY?.img ? best?.bestRevY?.img : "/images/undefined.png"} />
                        <span>{best?.bestRevY ? best.bestRevY.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">
                        {/* <div>
                            <p>Broj dealova</p>
                            <p>{best?.bestRevY ? Number(best.bestRevY.yesterday.deals.length) : "?"}</p>
                        </div> */}
                        <div style={{ width: "100%", background: "#d4dfff" }}>
                            <p>ADP</p>
                            <p>€{best?.bestRevY ? safeNumber(best.bestRevY.yesterday.revenue / best.bestRevY.yesterday.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći promet prethodnih 7 dana
                    </p>
                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" /> <span>€{best?.bestRevW ? safeNumber(best.bestRevW.week.revenue, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestRevW?.img ? best?.bestRevW?.img : "/images/undefined.png"} />
                        <span>{best?.bestRevW ? best.bestRevW.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">
                        {/* <div>
                            <p>Broj dealova</p>
                            <p>{best?.bestRevW ? Number(best.bestRevW.week.deals.length) : "?"}</p>
                        </div> */}
                        <div style={{ width: "100%", background: "rgba(4, 26, 139, 0.05)" }}>
                            <p>ADP</p>
                            <p>€{best?.bestRevW ? safeNumber(best.bestRevW.week.revenue / best.bestRevW.week.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći promet prethodni mjesec
                    </p>
                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" /> <span>€{best?.bestRevM ? safeNumber(best.bestRevM.month.revenue, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestRevM?.img ? best?.bestRevM?.img : "/images/undefined.png"} />
                        <span>{best?.bestRevM ? best.bestRevM.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">
                        {/* <div>
                            <p>Broj dealova</p>
                            <p>{best?.bestRevM ? Number(best.bestRevM.month.deals.length) : "?"}</p>
                        </div> */}
                        <div style={{ width: "100%", background: "#6C4F2126" }}>
                            <p>ADP</p>
                            <p>€{best?.bestRevM ? safeNumber(best.bestRevM.month.revenue / best.bestRevM.month.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="component__agentsDashboard__content__table">
                <div className="component__agentsDashboard__content__table__kpi">
                    <div className="component__agentsDashboard__content__table__kpi__single">
                        <p>Izvještaj dealova za danas</p>
                        <div className="component__agentsDashboard__content__table__kpi__single__data">
                            <div>
                                <p>Promet</p>
                                <p>€ {totalData ? safeNumber(totalData.today.revenue, 2) : "?"}</p>
                            </div>
                            <div>
                                <p>Broj dealova</p>
                                <p>{totalData ? safeNumber(totalData.today.deals.length) : "?"}</p>
                            </div>
                            <div>
                                <p>ADP</p>
                                <p>€{totalData ? safeNumber(totalData.today.revenue / totalData.today.deals.length, 2) : "?"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="component__agentsDashboard__content__table__kpi__single">
                        <p>Izvještaj dealova za jučer</p>
                        <div className="component__agentsDashboard__content__table__kpi__single__data">
                            <div>
                                <p>Promet</p>
                                <p>€{totalData ? safeNumber(totalData.yesterday.revenue, 2) : "?"}</p>
                            </div>
                            <div>
                                <p>Broj dealova</p>
                                <p>{totalData ? safeNumber(totalData.yesterday.deals.length) : "?"}</p>
                            </div>
                            <div>
                                <p>ADP</p>
                                <p>€{totalData ? safeNumber(totalData.yesterday.revenue / totalData.yesterday.deals.length, 2) : "?"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__table__content" style={{
                    height: visibleData?.length === itemsPerPage ? "calc(100% - 160px)" : "auto"
                }}>
                    <div className="component__agentsDashboard__content__table__content__head">
                        <p>No.</p>
                        <p>Ime i prezime</p>
                        <p>Danas
                            <div>
                                <p>Promet</p>
                                <p>ADP</p>
                                <p>AR</p>
                            </div>
                        </p>
                        <p>Jučer
                            <div>
                                <p>Promet</p>
                                <p>ADP</p>
                                <p>AR</p>
                            </div>
                        </p>
                        <p>7 dana
                            <div>
                                <p>Promet</p>
                                <p>ADP</p>
                                <p>AR</p>
                            </div>
                        </p>
                        <p>Prethodni mjesec
                            <div>
                                <p>Promet</p>
                                <p>ADP</p>
                                <p>AR</p>
                            </div>
                        </p>
                    </div>
                    {visibleData && visibleData.map((usr, index) => {
                        return <div style={{
                            height: visibleData?.length === itemsPerPage ? "auto" : "39px"
                        }} className={`component__agentsDashboard__content__table__content__user ${index % 2 !== 0 ? "component__agentsDashboard__content__table__content__user-dark" : ""}`}>
                            <p>{index + 1 + startIndex / itemsPerPage * itemsPerPage}.</p>
                            <p><img src={usr.img ? usr.img : "/images/undefined.png"} /> {usr.name} </p>
                            <div>
                                <p>€{safeNumber(usr.today.revenue)}</p>
                                <p>€{safeNumber(usr.today.revenue / usr.today.deals.length)}</p>
                                <p>{safeNumber((usr.today.deals.length / usr.today.leads.length) * 100)}%</p>
                            </div>
                            <div>
                                <p>€{safeNumber(usr.yesterday.revenue)}</p>
                                <p>€{safeNumber(usr.yesterday.revenue / usr.yesterday.deals.length)}</p>
                                <p>{safeNumber((usr.yesterday.deals.length / usr.yesterday.leads.length) * 100)}%</p>
                            </div>
                            <div>
                                <p>€{safeNumber(usr.week.revenue)}</p>
                                <p>€{safeNumber(usr.week.revenue / usr.week.deals.length)}</p>
                                <p>{safeNumber((usr.week.deals.length / usr.week.leads.length) * 100)}%</p>
                            </div>
                            <div>
                                <p>€{safeNumber(usr.month.revenue)}</p>
                                <p>€{safeNumber(usr.month.revenue / usr.month.deals.length)}</p>
                                <p>{safeNumber((usr.month.deals.length / usr.month.leads.length) * 100)}%</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className="component__agentsDashboard__content__kpisLeft">
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći bonus danas
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" />  <span>€{best?.bestBonusT ? safeNumber(best.bestBonusT.today.bonus, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestBonusT?.img ? best?.bestBonusT?.img : "/images/undefined.png"} />
                        <span>{best?.bestBonusT ? best.bestBonusT.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">

                        <div style={{ width: "100%", background: "#29653a1f" }}>
                            <p>Broj dealova</p>
                            <p>{best?.bestBonusT ? safeNumber(best.bestBonusT.today.deals.length) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći bonus jučer
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" />  <span>€{best?.bestBonusY ? safeNumber(best.bestBonusY.yesterday.bonus, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestBonusY?.img ? best?.bestBonusY?.img : "/images/undefined.png"} />
                        <span>{best?.bestBonusY ? best.bestBonusY.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">

                        <div style={{ width: "100%" }}>
                            <p>Broj dealova</p>
                            <p>{best?.bestBonusY ? safeNumber(best.bestBonusY.yesterday.deals.length) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći bonus prethodnih 7 dana
                    </p>
                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" />  <span>€{best?.bestBonusW ? safeNumber(best.bestBonusW.week.bonus, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestBonusW?.img ? best?.bestBonusW?.img : "/images/undefined.png"} />
                        <span>{best?.bestBonusW ? best.bestBonusW.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">

                        <div style={{ width: "100%", background: "#041A8B0D" }}>
                            <p>ADP</p>
                            <p>€{best?.bestBonusW ? safeNumber(best.bestBonusW.week.revenue / best.bestBonusW.week.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
                <div className="component__agentsDashboard__content__kpisLeft__single">
                    <p className="component__agentsDashboard__content__kpisLeft__single__title">
                        Najveći bonus prethodni mjesec
                    </p>
                    <p className="component__agentsDashboard__content__kpisLeft__single__amount">
                        <img src="/images/trophy.png" /> <span> €{best?.bestBonusM ? safeNumber(best.bestBonusM.month.bonus, 2) : "?"}</span>
                    </p>

                    <p className="component__agentsDashboard__content__kpisLeft__single__agent">
                        <img src={best?.bestBonusM?.img ? best?.bestBonusM?.img : "/images/undefined.png"} />
                        <span>{best?.bestBonusM ? best.bestBonusM.name : "?"}</span>
                    </p>
                    <div className="component__agentsDashboard__content__kpisLeft__single__stats">

                        <div style={{ width: "100%", background: "#6C4F2126" }}>
                            <p>ADP</p>
                            <p>€{best?.bestBonusM ? safeNumber(best.bestBonusM.month.revenue / best.bestBonusM.month.deals.length, 2) : "?"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
};


export default AgentsDashboard;











